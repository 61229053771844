<template>
  <div>
     <qzf-tabs v-model:value="value">
      <qzf-tabs-item label="税局" name="1" :cache="false">
        <autoHomeSj ref="autoHomeSj"></autoHomeSj>
      </qzf-tabs-item>
      <qzf-tabs-item label="社保" name="2" :cache="false">
        <autoHomeSb ref="autoHomeSb"></autoHomeSb>
      </qzf-tabs-item>
    </qzf-tabs>
  </div>
</template>

<script>
import autoHomeSj from "./components/autoHomeSj.vue";
import autoHomeSb from "./components/autoHomeSb.vue";
export default {
  name: "autoHomeIndex",
  components: { autoHomeSj, autoHomeSb },
  data() {
    return {
      value: "1",
    };
  },
  mounted() {
    this.initBus();
  },

  methods: {
    initBus() {
      this.$bus.off("confirmStatusUpdate");
      this.$bus.on("confirmStatusUpdate", (val) => {
        setTimeout(() => {
          this.$refs.autoHomeSj.getList();
          this.$refs.autoHomeSb.getList();
        });
      });
    },
  },
};
</script>

<style></style>
