<template>
  <div style="width:100%;padding:10px 10px 0">
    <el-row :gutter="20">
      <el-col :xs="6" :sm="6" :lg="6">
        <div class="top_left">
          <h5>记账凭证</h5>
          <div class="left_total">凭证总数：<span class="theme_font">{{ voucher }}</span>&nbsp;张&nbsp;&nbsp;附件：<span
              class="theme_font">{{ billVoucherCount }}</span>&nbsp;张</div>
          <div class="left_info">
            <p>清单凭证&nbsp;{{ vouBill }}&nbsp;张</p>
            <p>结转凭证&nbsp;{{ vouCarry }}&nbsp;张</p>
            <p>税金凭证&nbsp;{{ vouTax }}&nbsp;张</p>
            <p>手工凭证&nbsp;{{ vouHand }}&nbsp;张</p>
          </div>
          <div class="btns">
            <div @click="gotoVoucher" v-if="$buttonStatus('pz_tj')">
              <el-icon>
                <CirclePlus />
              </el-icon><span> 新增凭证</span>
            </div>
            <div @click="gotoSubject">
              <el-icon>
                <Tickets />
              </el-icon><span> 科目余额表</span>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :xs="10" :sm="10" :lg="10">
        <div style="height:320px;position: relative;">
          <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="票据统计" name="fourth">
              <div class="bill" style="height:245px" v-loading="loading">
                <div class="bill_left">
                  <div class="bill_one">进项发票：<span class="theme_font">{{ inSum.count
                      }}</span>&nbsp;张&nbsp;&nbsp;&nbsp;金额：<span>¥&nbsp;{{ $comdify(inSum.amount) }}</span>
                    <div class="income_style" v-if="comType == '2'">
                      <div class="income_each" style="margin-right: 25px;">
                        <div class="income_font">已认证进项票&nbsp;&nbsp;<span>{{ inYrzSum.count }}</span>&nbsp;张</div>
                        <div style="margin-top:10px;padding-left:10px">¥&nbsp;{{ $comdify(inYrzSum.amount) }}</div>
                      </div>
                      <div class="income_each">
                        <div class="income_font">累计未认证进项票&nbsp;&nbsp;<span>{{ inWrzSum.count }}</span>&nbsp;张</div>
                        <div style="margin-top:10px;padding-left:10px">¥&nbsp;{{ $comdify(inWrzSum.amount) }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="bill_one">销项发票：<span class="theme_font">{{ outSum.count
                      }}</span>&nbsp;张&nbsp;&nbsp;&nbsp;金额：<span>¥&nbsp;{{ $comdify(outSum.amount) }}</span></div>
                  <div class="bill_one" style="padding-top: 20px;">预估应交增值税&nbsp;&nbsp;<span>¥&nbsp;{{
                    $comdify(yjskje.amount) }}</span></div>
                </div>
                <div class="bill_right">
                  <invoiceSum ref="invoiceSum" />
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane name="sixth" label="季度销售额统计">
              <div v-loading="loading2" style="height:244px">
                <div class="total_amount">
                  <div class="bottom_btn">
                    <label>季度销售额合计：</label>
                    <p>{{ this.$comdify(jdAmount) }}</p>
                  </div>
                </div>
                <div class="month_box">
                  <div class="month_item" v-for="item in jdList" :key="item.period"
                    :style="{ width: `calc(100% / ${jdList.length})` }">
                    <h4>{{ item.period }}</h4>
                    <div>{{ item.sumTotal }}</div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane name="fifth" v-if="comType == '1'">
              <template #label>
                <el-badge value="预警" :hidden="totalAmountOut < 4000000">
                  <span>销售额统计</span>
                </el-badge>
                <!-- <div style="position: relative;"> <i class="iconfont icon-sanjiaotanhao" style="color:#e6a23c;position: absolute;font-size: 13px;top: -10px;right:-14px" v-if="totalAmountOut > 4000000"></i> </div>  -->
              </template>
              <div v-loading="loading1">
                <div class="total_amount">
                  <div class="bottom_btn">
                    <label>连续12个月收入合计：</label>
                    <p>{{ this.$comdify(totalAmountOut) }}</p>
                  </div>
                </div>
                <div class="bottom_month_amount">
                  <div class="top_save">
                    <p>请补充各月不含税收入</p>
                    <!-- <el-button type="primary" size="small" @click="saveValue()" v-if="!fromComId">
                      <i
                        class="iconfont icon-baocun"
                        style="margin-right:4px;font-size: 14px;"
                      ></i>
                      <span> 保存</span>
                    </el-button> -->
                  </div>
                  <div class="month_box">
                    <div class="month_item" v-for="itemM in outStatisticsList" :key="itemM.id">
                      <h4>{{ itemM.period }}</h4>
                      <div class="edit_style" @click="editOut(itemM)">{{ itemM.amount }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane name="first" label="税款统计">
              <div v-loading="loading2">
                <taxSum ref="taxSum" />
              </div>
            </el-tab-pane>
          </el-tabs>
          <span class="right_box">
            纳税人类型：{{ comType == '1' ? '小规模纳税人' : '一般纳税人' }}
          </span>
        </div>
      </el-col>
      <el-col :xs="8" :sm="8" :lg="8">
        <div style="width:100%;height:320px;border: 1px solid #d7d7d7;padding: 0 10px;">
          <el-tabs v-model="activeIndex" @tab-click="handleTabClick">
            <el-tab-pane label="增值税波动图" name="1">
              <vatTax ref="vatTax" :activeIndex="activeIndex" />
            </el-tab-pane>
            <el-tab-pane label="所得税变化图" name="2">
              <qysdsTax ref="qysdsTax" :activeIndex="activeIndex" />
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top:10px">
      <el-col :xs="16" :sm="16" :lg="16" v-loading="loading">
        <rightAllTotal ref="rightAllTotal"></rightAllTotal>
      </el-col>
      <el-col :xs="8" :sm="8" :lg="8">
        <div :style="{ height: contentStyleObj }" class="bottom_right">
          <div class="bottom_icon" style="float: left;">
            <div class="each_style">
              <div class="each_size">
                <div class="left_icon">
                  <img src="../../assets/home7.png" alt="">
                </div>
                <div>
                  <p class="right_title">银行存款</p>
                  <p class="right_amount">{{ statInfo.yhckAmount ? $comdify(statInfo.yhckAmount) : '--' }}</p>
                </div>
              </div>
            </div>
            <div class="each_style">
              <div class="each_size">
                <div class="left_icon">
                  <img src="../../assets/home3.png" alt="">
                </div>
                <div>
                  <p class="right_title">应收账款</p>
                  <p class="right_amount">{{ statInfo.yszkAmount ? $comdify(statInfo.yszkAmount) : '--' }}</p>
                </div>
              </div>
            </div>
            <div class="each_style">
              <div class="each_size">
                <div class="left_icon">
                  <img src="../../assets/home1.png" alt="">
                </div>
                <div>
                  <p class="right_title">主营业务收入</p>
                  <p style="font-size:12px;">本年：<span class="right_amount">{{ statInfo.zyywsrAmountYear ?
                    $comdify(statInfo.zyywsrAmountYear)
                    :
                    '--' }}</span></p>
                  <p style="font-size:12px;">本期：<span class="right_amount">{{ statInfo.zyywsrAmount ?
                    $comdify(statInfo.zyywsrAmount)
                    :
                    '--' }}</span></p>
                </div>
              </div>
            </div>
            <div class="each_style">
              <div class="each_size">
                <div class="left_icon">
                  <img src="../../assets/home5.png" alt="">
                </div>
                <div>
                  <p class="right_title">销售费用</p>
                  <p style="font-size:12px;">本年：<span class="right_amount">{{ statInfo.xsfyAmountYear ?
                    $comdify(statInfo.xsfyAmountYear)
                    :
                    '--' }}</span></p>
                  <p style="font-size:12px;">本期：<span class="right_amount">{{ statInfo.xsfyAmount ?
                    $comdify(statInfo.xsfyAmount)
                    :
                    '--' }}</span></p>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom_icon" style="float: right;">
            <div class="each_style">
              <div class="each_size">
                <div class="left_icon">
                  <img src="../../assets/home8.png" alt="">
                </div>
                <div>
                  <p class="right_title">库存现金</p>
                  <p></p>
                  <p class="right_amount">{{ statInfo.kcxjAmount ? $comdify(statInfo.kcxjAmount) : '--' }}</p>
                </div>
              </div>
            </div>
            <div class="each_style">
              <div class="each_size">
                <div class="left_icon">
                  <img src="../../assets/home4.png" alt="">
                </div>
                <div>
                  <p class="right_title">应付账款</p>
                  <p class="right_amount">{{ statInfo.yfzkAmount ? $comdify(statInfo.yfzkAmount) : '--' }}</p>
                </div>
              </div>
            </div>
            <div class="each_style">
              <div class="each_size">
                <div class="left_icon">
                  <img src="../../assets/home2.png" alt="">
                </div>
                <div>
                  <p class="right_title">库存商品</p>
                  <p class="right_amount">{{ statInfo.kcspAmount ? $comdify(statInfo.kcspAmount) : '--' }}</p>
                </div>
              </div>
            </div>
            <div class="each_style">
              <div class="each_size">
                <div class="left_icon">
                  <img src="../../assets/home6.png" alt="">
                </div>
                <div>
                  <p class="right_title">管理费用</p>
                  <p style="font-size:12px;">本年：<span class="right_amount">{{ statInfo.glfyAmountYear ?
                    $comdify(statInfo.glfyAmountYear)
                    :
                    '--' }}</span></p>
                  <p style="font-size:12px;">本期：<span class="right_amount">{{ statInfo.glfyAmount ?
                    $comdify(statInfo.glfyAmount)
                    :
                    '--' }}</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>

    </el-row>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { indexTaxItem, getOutStatistics, outStatisticsSave, indexTax2, outStatisticsMonthSave } from "@/api/home";
import taxSum from "./components/taxSum";
import rightAllTotal from "./components/rightAllTotal";
import invoiceSum from './components/invoiceSum.vue';
import vatTax from "./components/vatTax.vue";
import qysdsTax from './components/qysdsTax.vue'
export default defineComponent({
  //name: "etaxHomeIndex",
  name: "",
  components: {
    taxSum,
    rightAllTotal,
    invoiceSum,
    vatTax,
    qysdsTax
  },
  created() {
    this.contentStyleObj = this.$getHeight(450)
    this.init()
  },
  data() {
    return {
      activeName: "fourth",
      voucher: 0,
      billVoucherCount: 0,
      vouBill: 0,
      vouCarry: 0,
      vouTax: 0,
      vouHand: 0,
      outStatisticsList: [],
      totalAmountOut: 0,
      flag: false,
      listQuery: {
        comId: this.$store.getters["user/comInfo"].comId * 1,
        period: this.$store.getters["user/comInfo"].period,
      },
      loading: false,
      contentStyleObj: {},
      activeIndex: "1",
      statInfo: {},
      inSum: {},
      outSum: {},
      inWrzSum: {},
      inYrzSum: {},
      yjskje: {},
      loading1: false,
      comType: this.$store.getters["user/comInfo"].comType,
      zzsData: [],
      sdsData: [],
      loading2: false,
      jdList: [],
      jdAmount: 0,
      fromComId: this.$store.getters["user/user"].fromComId,
    };
  },
  methods: {
    init() {
      this.loading = true
      indexTax2(this.listQuery).then(res => {
        this.loading = false
        if (res.data.msg == 'success') {
          let data = res.data.data;
          this.$refs.rightAllTotal?.init(data);
          this.yjskje = data.yjskje
          this.voucher = data.voucher.count;
          this.billVoucherCount = data.voucher.billCount;
          this.vouBill = data.vouBill.count;
          this.vouCarry = data.vouCarry.count;
          this.vouTax = data.vouTax.count;
          this.vouHand = data.vouHand.count;
          this.statInfo = data.statInfo;
          this.inSum = data.inSum;
          this.outSum = data.outSum;
          this.inWrzSum = data.inWrzSum;
          this.inYrzSum = data.inYrzSum;
          this.zzsData = data.zzsbdt;
          this.sdsData = data.sdsbdt
          this.$refs.invoiceSum?.init(data.sum);
          this.$refs.vatTax?.init(data.zzsbdt)
          this.$refs.qysdsTax?.init(data.sdsbdt)
        }
      })
    },
    getOutList() {
      this.loading1 = true;
      getOutStatistics({}).then((res) => {
        this.loading1 = false;
        this.outStatisticsList = res.data.data.list;
        let totalAmountOut = 0;
        this.outStatisticsList.map((v) => {
          totalAmountOut += v.amount;
        });
        this.totalAmountOut = totalAmountOut.toFixed(2);
      });
    },
    getJdInfo(flag) {
      this.loading2 = true
      indexTaxItem({}).then(res => {
        this.loading2 = false
        if (flag) {
          this.$refs.taxSum.init(res.data.data.taxMap)
        } else {
          this.jdList = res.data.data.totals
          let amount = 0;
          this.jdList.map((v) => {
            amount += v.sumTotal;
          });
          this.jdAmount = amount.toFixed(2);
        }
      })
    },
    handleTabClick(tab) {
      if (tab.props.name == "2") {
        this.$refs.qysdsTax.init(this.sdsData)
      }
    },
    handleClick(tab, event) {
      if (tab.props.name == "fifth") {
        this.getOutList();
      } else if (tab.props.name == "sixth" || tab.props.name == "first") {
        let flag = tab.props.name == "first" ? true : false
        this.getJdInfo(flag)
      }
    },
    saveValue() {
      outStatisticsSave(this.outStatisticsList).then((res) => {
        if (res.data.msg == "success") {
          this.getOutList();
          this.$qzfMessage("保存成功");
        }
      });
    },
    gotoSubject() {
      this.$router.push({
        path: "/bookkeeps/accountBook",
        name: "accountBook",
      });
    },
    gotoVoucher() {
      this.$store.dispatch('commons/getSubjects', this.listQuery.comId)
      this.$store.dispatch("commons/setParam", { addVoucherId: 0 });
      this.$store.dispatch("tagsView/delCachedViewByName", "addVoucher");
      this.$router.push({
        path: "/bookkeeps/addVoucher",
        name: "addVoucher",
      });
    },
    editOut(row) {
      if (this.fromComId) return
      this.$prompt(`请输入${row.period}不含税收入`, '修改不含税收入', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: '',
        beforeClose: (action, instance, done) => {
          if (action == 'confirm') {
            let value = instance.inputValue;
            if (isNaN(Number(value))) {
              this.$qzfMessage('请输入正确的金额', 1)
            } else {
              let param = {
                id: row.id,
                amount: Number(value)
              }
              outStatisticsMonthSave(param).then(res => {
                done();
                if (res.data.msg == 'success') {
                  this.getOutList();
                  this.$qzfMessage("保存成功");
                }
              })
            }
          } else {
            done()
          }
        }
      })
    }
  },
});
</script>

<style lang="scss" scoped>
:deep(.el-tabs__item) {
  padding: 0 10px;
}

:deep(.el-tabs__item:nth-last-child(2)) {
  padding: 0 30px !important;
}

:deep(.el-tabs__item:last-child) {
  padding-right: 10px !important;
}

.card-header {
  height: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box-card {
  width: 30%;
}

.title {
  font-size: 16px;
  font-weight: 600;
}

.spbh {
  color: var(--themeColor, #17a2b8);
  position: relative;
  float: right;
  top: -60px;

  span {
    margin-right: 10px;
    margin-left: 10px;

    img {
      position: relative;
      top: 7px;
    }
  }
}

.noDate {
  text-align: center;
  font-size: 31px;
  font-weight: 500;
  color: #1482f0;
  margin-top: -104px;
}

.rightSpan {
  width: 30%;
  margin-right: 10px;
  height: 100%;
  background-color: #eee;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  position: relative;
}

.left_item {
  width: 100%;
  margin: 0 auto;
  background: #eee;
  // box-shadow:2px 2px 10px rgba(110, 110, 110, 0.2);
  padding: 15px;
  // margin-left: 20px;

  height: 360px;

  h5 {
    margin-top: 20px;
    font-size: 16px;
    color: #000;
    line-height: 32px;
    font-weight: normal;
    padding-left: 30px;
  }

  p {
    float: left;
    margin-right: 15px;
    width: 46%;
    font-size: 14px;
    color: #333;
    line-height: 32px;
  }

  p:last-child {
    margin-right: 0;
  }
}

.center_fonts {
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
  padding-left: 30px;

  .item_font {
    width: 100%;
    margin-top: 8px;

    p {
      float: left;
      width: 60%;
      font-size: 13px;
      color: #333;
      line-height: 24px;

      span {
        padding: 0 5px 0 10px;
        color: var(--themeColor, #17a2b8);
      }
    }

    h6 {
      float: left;
      width: 30%;
      font-size: 13px;
      color: var(--themeColor, #17a2b8);
      line-height: 24px;
      cursor: pointer;
    }
  }
}

.btns {
  width: 100%;
  margin: 0 auto;
  margin-top: 15px;

  div {
    width: 80%;
    margin: 0 auto;
    line-height: 32px;
    font-size: 14px;
    color: #fff;
    background: var(--themeColor, #17a2b8);
    margin-top: 20px;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;
  }
}

.total_amount {
  width: 100%;
  margin: 0 auto;

  h5 {
    font-size: 15px;
    color: #000;
    line-height: 26px;
    font-weight: normal;
  }

  h6 {
    font-size: 13px;
    margin-top: 5px;
    color: #333;
    line-height: 26px;
    font-weight: normal;
  }

  .bottom_btn {
    width: 100%;
    margin-top: 5px;
    display: flex;
    flex-direction: row;

    label {
      line-height: 26px;
      color: #333;
      font-size: 13px;
      font-weight: normal;
    }

    p {
      min-width: 200px;
      line-height: 26px;
      color: #f15a23;
      font-size: 13px;
      font-weight: normal;
    }
  }
}

.bottom_month_amount {
  height: 202px;
  width: 100%;
  margin: 0 auto;
  margin-top: 15px;
  border-top: 1px solid #c2c2c2;

  .top_save {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    p {
      line-height: 28px;
      color: #333;
      font-size: 13px;
      font-weight: normal;
    }
  }
}

.month_box {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  margin-top: 10px;
  border-right: 1px solid #c2c2c2;
  border-top: 1px solid #c2c2c2;

  .month_item {
    width: 16.66666%;
    cursor: pointer;

    h4 {
      width: 100%;
      line-height: 30px;
      height: 30px;
      background: #eee;
      color: #333;
      font-size: 13px;
      text-align: center;
      border-left: 1px solid #c2c2c2;
      border-bottom: 1px solid #c2c2c2;
    }

    input {
      width: 100%;
      line-height: 30px;
      height: 30px;
      padding: 0 0px;
      color: #333;
      font-size: 13px;
      text-align: center;
      border-bottom: 1px solid #c2c2c2;
      border-left: 1px solid #c2c2c2;
    }

    div {
      width: 100%;
      line-height: 30px;
      height: 30px;
      padding: 0 0px;
      color: #333;
      font-size: 13px;
      text-align: center;
      border-bottom: 1px solid #c2c2c2;
      border-left: 1px solid #c2c2c2;
    }
  }
}

.iconStyle {
  font-size: 50px;
  color: #fff;
  position: absolute;
  bottom: 20px;
}
</style>
<style lang="scss" scoped>
.top_left {
  border: 1px solid #d7d7d7;
  height: 320px;
  padding: 10px 10px;

  h5 {
    font-size: 14px;
    font-weight: 500;
  }

  h5::before {
    border-left: 3px solid var(--themeColor, #17a2b8);
    content: '';
    display: inline-block;
    height: 13px;
    position: relative;
    margin-right: 5px;
  }

  .left_total {
    padding: 15px 0;
    color: #333;
    font-size: 13px;
    font-weight: 500;
    margin-left: 10%;

  }

  .left_info {
    font-size: 12px;
    margin-left: 10%;
    color: #5f5f5f;

    p {
      margin-bottom: 5px;
      letter-spacing: 1px;
    }
  }
}

.bill {
  .bill_left {
    font-size: 13px;
    font-weight: 500;
    float: left;
    width: 310px;

    .bill_one {
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #efefef;
    }

    .bill_one::before {
      content: '';
      background: var(--themeColor, #17a2b8);
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: relative;
      margin-right: 10px;
    }
  }

  .bill_one:last-child {
    border-bottom: none;
  }

  .bill_right {
    float: right;
    width: calc(100% - 310px);
  }
}

.theme_font {
  color: var(--themeColor, #17a2b8);
  font-size: 14px;
}

.income_style {
  display: flex;
  padding-left: 2px;
  padding-top: 15px;
  font-size: 12px;

  .income_font::before {
    content: '';
    background: #e6e6e6;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: relative;
    margin-right: 5px;
  }

  .income_font {
    margin-top: 10px;
  }
}

.bottom_right {
  border: 1px solid #d7d7d7;
  padding: 10px;

  .bottom_icon {
    width: 48%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    .each_style {
      height: 23%;
      border: 1px solid #efefef;
      position: relative;
    }

    .each_size {
      width: 90%;
      height: 100%;
      display: flex;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .left_icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      // background-color: pink;
      margin-right: 15px;
      text-align: center;
      line-height: 40px;

      img {
        width: 100%;
        height: 100%;
      }

      .iconfont {
        font-size: 20px;
        color: #fff;
      }

    }

    .right_title {
      font-size: 13px;
      letter-spacing: 1px;
      font-weight: 500;
      margin-bottom: 3px;
      color: #333;
    }

    .right_amount {
      font-size: 12px;
      font-weight: 600;
      color: #656565;
    }
  }
}

.right_box {
  position: absolute;
  top: 11px;
  right: 10px;
  font-size: 13px;
  color: #999;
}

:deep(.el-badge__content.is-fixed) {
  top: 11px;
  right: 2px;
}

.edit_style {
  text-decoration: underline;
  cursor: pointer;
}
</style>
