<template>
  <div>
    <div class="auto_content">
      <div class="auto_content_size">
        <div style="width: 50px">统计</div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-gongsi1"></i>
          </div>
          <div class="each_text">
            <div>企业总数</div>
            <span>{{ info.countTotal }}</span>家
          </div>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-zidong"></i>
          </div>
          <div
            class="each_text"
          >
            <div>授权在线</div>
            <span>{{ info.countEmpower }}</span>家
          </div>
        </div>
        <div class="each"  @click="getNewListTotal()">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div
            class="each_text"
          >
            <div>本期用户</div>
            <a>
              <span>{{ info.countPeriod }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each" @click="getWjz">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div
            class="each_text"
          >
            <div>异常</div>
            <a><span>{{ info.countYc }}</span>家</a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(1)">
          <div class="icon_bg">
            <i class="iconfont icon-piaojucaiji1"></i>
          </div>
          <div
            class="each_text"
          >
            <div>已完成</div>
            <a>
              <span>{{ info.countOk }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(6)">
          <div class="icon_bg">
            <i class="iconfont icon-piaojucaiji1"></i>
          </div>
          <div
            class="each_text"
          >
            <div>未完成</div>
            <a>
              <span>{{ info.countWwc }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(2)">
          <div class="icon_bg">
            <i class="iconfont icon-lishishuju1"></i>
          </div>
          <div
            class="each_text"
          >
            <div>进行中</div>
            <a>
              <span>{{ info.countJxz }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(3)">
          <div class="icon_bg">
            <i class="iconfont icon-shouquanyemian1"></i>
          </div>
          <div
            class="each_text"
          >
            <div>待确认</div>
            <a>
              <span>{{ info.countDqr }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(4)">
          <div class="icon_bg">
            <i class="iconfont icon-chushihuacaozuo"></i>
          </div>
          <div
            class="each_text"
          >
            <div>未开始</div>
            <a>
              <span>{{ info.countWks }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(5)">
          <div class="icon_bg">
            <i class="iconfont icon-jieyuekehu1"></i>
          </div>
          <div
            class="each_text"
          >
            <div>失败</div>
            <a>
              <span>{{ info.countErr }}</span
              >家
            </a>
          </div>
        </div>
      </div>
      <div class="date_select">
        <qzf-search-period v-model:period="listQuery.period" :clearable="false" style="width:100px;margin:0 5px" @change="getNewList"></qzf-search-period>
      </div>
      <!-- <el-button size="small" type="primary" @click="goStatistics" icon="Collection" style="position: relative;right: 1%;top: 13px;z-index: 9;float: right;">预计人工降耗金额</el-button> -->
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane name="税款申报">
        <template #label>
          税款申报({{ info.countSkSb }})
        </template>
      </el-tab-pane>
      <el-tab-pane name="零申报">
        <template #label>
          零申报({{ info.countLsb }})
        </template>
      </el-tab-pane>
    <div class="top_select">
      <div>
        <el-button icon="RefreshRight" size="small" @click="getList()"></el-button>
        <el-input size="small" placeholder="公司名称" v-model.trim="listQuery.name" style="width:160px;margin-left: 5px;" @keyup.enter="getList" clearable></el-input>
        <el-button size="small" type="primary" @click="getList" style="margin-right:10px">
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:11px 0px 0px 15px;width:600px" label-width="110px" class="style_form" size="small" :inline="true">
          <el-form-item label="纳税人类型：">   
            <selecttaxtype v-model:type="listQuery.type" style="width:140px"></selecttaxtype>
          </el-form-item>
          <el-form-item label="选择人员：">
            <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width:140px"></selectuser>
          </el-form-item>
          <el-form-item label="税局："> 
            <selectcity v-model:citys="listQuery.districtCode" style="width:140px"></selectcity>
          </el-form-item>
          <el-form-item label="登录状态：">   
            <selectAccreditStatus v-model:type="listQuery.keepStatus" style="width:140px"></selectAccreditStatus>
          </el-form-item>
          <el-form-item label="采集状态："> 
            <autoHomeSelect v-model:modelValue="listQuery.sheBaoCjStatus" placeholder="请选择采集状态" loadingText="采集中"/>
          </el-form-item>
          <el-form-item label="确认状态："> 
            <autoHomeSelect v-model:modelValue="listQuery.sheBaoqrStatus" placeholder="请选择确认状态" type="qr"/>
          </el-form-item>
          <el-form-item label="申报状态："> 
            <autoHomeSelect v-model:modelValue="listQuery.sheBaosbStatus" placeholder="请选择申报状态" loadingText="申报中"/>
          </el-form-item>
          <el-form-item label="扣款状态："> 
            <autoHomeSelect v-model:modelValue="listQuery.sheBaokkStatus" placeholder="请选择扣款状态" loadingText="扣款中"/>
          </el-form-item>
          <el-form-item label="检查状态："> 
            <autoHomeSelect v-model:modelValue="listQuery.sheBaojcStatus" placeholder="请选择检查状态" loadingText="检查中"/>
          </el-form-item>
          </el-form>
        </search>
      </div>
      <div>
        <!-- <el-button size="small" type="primary" @click="checkList" icon="List" plain :disabled="!$buttonStatus('zdjz_ztqr')">账套迁入</el-button>
        <el-button size="small" type="primary" @click="moveOut" icon="List" plain :disabled="!$buttonStatus('zdjz_ztqc')">账套迁出</el-button> -->
        <el-button size="small" type="success" @click="reStart" icon="SwitchButton" v-if="autoButtonStatus" :disabled="havingTask || !$buttonStatus('zdjz_cq')">重启</el-button>
        <el-button size="small" type="success" @click="autoStart" :disabled="havingTask || !$buttonStatus('zdjz_ts')" v-if="autoButtonStatus"><i class="iconfont icon-a-xinjianzidingyichicun21x1" ></i>调试</el-button>
        <el-button @click="confirmAll()" type="primary" size="small" plain icon="Checked">批量确认</el-button>
        <!-- <el-button size="small" type="primary" @click="setting" icon="Setting" :disabled="!$buttonStatus('zdjz_xgpz')">做账习惯配置</el-button> -->
        <!-- <el-button size="small" type="success" @click="daochu" icon="Folder" :loading="daochuLoading" :disabled="!$buttonStatus('zdjz_dc')">导出</el-button> -->
      </div>
    </div>

    <el-table stripe :data="list" :height="contentStyleObj" style="width: 100%" border @selection-change="handleSelectionChange" v-loading="loading">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" prop="id" type="selection" width="55" />
      <el-table-column prop="name" label="公司名称" min-width="240" fixed="left">
        <template #default="scope">
          <TagNameCopy :row="scope.row" idKey="comId">
            <template v-slot>
              <el-tooltip content="做账习惯配置" placement="top" effect="dark">
                <i class="iconfont icon-guanlishezhi" v-if="$buttonStatus('zdjz_xgpz')" style="color:var(--themeColor,#17a2b8);cursor: pointer;font-size: 15px;margin-left:8px" @click="settingOne(scope.row)">
                </i>
              </el-tooltip>
            </template>
          </TagNameCopy>
        </template>
      </el-table-column>
      <el-table-column label="采集"  min-width="170">
        <template #default="scope">
          <div class="item_icon div_p">
            <p>
              <i :class="$filterAutoStatusIcon(scope.row.shebaocj_status)"></i>
              <el-tooltip effect="dark" :disabled="!scope.row.shebaocj_err" placement="top-start">
                <template #content>
                  <div v-html="scope.row.shebaocj_err"></div>
                </template>
                <div style="display: inline-block;">
                  <span :class="$filterAutoStatusWord(scope.row.shebaocj_status)">{{$filterAutoStatusText(scope.row.shebaocj_status,'cj')}}</span>
                  <i class="iconfont icon-wenhao" v-if="scope.row.shebaocj_err" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                </div>
              </el-tooltip>
            </p>
            <div>
              <el-button @click="checkShebao(scope.row)" :disabled="!$buttonStatus('zdjz_ck')" size="small" link style="padding: 4px 3px;margin-left: 0;">查看</el-button>
              <el-tooltip effect="dark" placement="top-start" content="继续">
                <el-button link size="small" :disabled="scope.row.btnStatus || !$buttonStatus('zdjz_jx')" style="margin-left: 2px;" v-if="scope.row.shebaocj_status==4" @click="continueTask(scope.row)"><i class="iconfont icon-jiantou-you"></i></el-button>
              </el-tooltip>
              <!-- <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'shebaocj','采集')"></i> -->
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="社保确认"  width="120">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i :class="$filterAutoStatusIcon(scope.row.shebaoqr_status)"></i>
                <el-tooltip effect="dark" :disabled="!scope.row.shebaoqr_err" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.shebaoqr_err"></div>
                  </template>
                  <div style="display: inline-block;">
                    <span :class="$filterAutoStatusWord(scope.row.shebaoqr_status)">{{$filterAutoStatusText(scope.row.shebaoqr_status,'qr')}}</span>
                    <i class="iconfont icon-wenhao" v-if="scope.row.shebaoqr_err" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                  </div>
                </el-tooltip>
                <el-button @click="confirmAuto(scope.row,'shebaoqr','社保确认')" v-if="scope.row.shebaoqr_status==2"  size="small" link style="padding: 4px 3px;margin-top: -1px;">确认</el-button>
              </p>
              <el-tooltip effect="dark" placement="top-start" content="继续">
                <el-button link size="small" :disabled="scope.row.btnStatus || !$buttonStatus('zdjz_jx')" v-if="scope.row.shebaoqr_status==4" @click="continueTask(scope.row)"><i class="iconfont icon-jiantou-you"></i></el-button>
              </el-tooltip>
             <!--  <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'shebaoqr','单据确认')" v-if="scope.row.shebaoqr_status!=3 && scope.row.shebaoqr_status!=6 && scope.row.shebaoqr_status!=7"></i> -->
            </div>
          </template>
        </el-table-column>
      <el-table-column label="申报"  min-width="200">
        <template #default="scope">
          <div class="item_icon div_p">
            <p>
              <span v-if="scope.row.shebaosb_img && [3,4,5].includes(scope.row.shebaosb_status)">
                <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-if="scope.row.shebaosb_status == 4" @click="open(scope.row, scope.$index,'shebaosb_img')"></i>
                <i class="iconfont icon-picture" style="color:#67c23a;font-size:16px;" v-if="scope.row.shebaosb_status == 3" @click="open(scope.row, scope.$index,'shebaosb_img')"></i>
                <i class="iconfont icon-picture" style="color:#e6a23c;font-size:16px;" v-if="scope.row.shebaosb_status == 5" @click="open(scope.row, scope.$index),'shebaosb_img'"></i>
              </span>
              <i v-else :class="$filterAutoStatusIcon(scope.row.shebaosb_status)"></i>
              <el-tooltip effect="dark" :disabled="!scope.row.shebaosb_err" placement="top-start">
                <template #content>
                  <div v-html="scope.row.shebaosb_err"></div>
                </template>
                <div style="display: inline-block;">
                  <span :class="$filterAutoStatusWord(scope.row.shebaosb_status)">{{$filterAutoStatusText(scope.row.shebaosb_status,'sb')}}</span>
                  <i class="iconfont icon-wenhao" v-if="scope.row.shebaosb_err" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                </div>
              </el-tooltip>
            </p>
            <div>
              <el-tooltip effect="dark" placement="top-start" content="继续">
                <el-button link size="small" :disabled="scope.row.btnStatus || !$buttonStatus('zdjz_jx')" style="margin-left: 0px;" v-if="scope.row.shebaosb_status==4" @click="continueTask(scope.row)"><i class="iconfont icon-jiantou-you"></i></el-button>
              </el-tooltip>
              <!-- <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'shebaosb','申报')" ></i> -->
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="扣款"  min-width="200">
        <template #default="scope">
          <div class="item_icon div_p">
            <p>
              <span v-if="scope.row.shebaokk_img && [3,4,5].includes(scope.row.shebaokk_status)">
                <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-if="scope.row.shebaokk_status == 4" @click="open(scope.row, scope.$index,'shebaokk_img')"></i>
                <i class="iconfont icon-picture" style="color:#67c23a;font-size:16px;" v-if="scope.row.shebaokk_status == 3" @click="open(scope.row, scope.$index,'shebaokk_img')"></i>
                <i class="iconfont icon-picture" style="color:#e6a23c;font-size:16px;" v-if="scope.row.shebaokk_status == 5" @click="open(scope.row, scope.$index),'shebaokk_img'"></i>
              </span>
              <i :class="$filterAutoStatusIcon(scope.row.shebaokk_status)"></i>
              <el-tooltip effect="dark" :disabled="!scope.row.shebaokk_err" placement="top-start">
                <template #content>
                  <div v-html="scope.row.shebaokk_err"></div>
                </template>
                <div style="display: inline-block;">
                  <span :class="$filterAutoStatusWord(scope.row.shebaokk_status)">{{$filterAutoStatusText(scope.row.shebaokk_status,'kk')}}</span>
                  <i class="iconfont icon-wenhao" v-if="scope.row.shebaokk_err" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                  <span style="margin-left:5px;font-size:13px;color:var(--themeColor,#17a2b8)" v-if="scope.row.shebaokk_status == 3">金额:{{(scope.row.shebaokk_amount).toFixed(2)}}</span>
                </div>
              </el-tooltip>
            </p>
            <div>
              <el-tooltip effect="dark" placement="top-start" content="继续">
                <el-button link size="small" :disabled="scope.row.btnStatus || !$buttonStatus('zdjz_jx')" v-if="scope.row.shebaokk_status==4" @click="continueTask(scope.row)"><i class="iconfont icon-jiantou-you"></i></el-button>
              </el-tooltip>
              <!-- <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'shebaokk','扣款')" ></i> -->
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="检查"  min-width="120">
        <template #default="scope">
          <div class="item_icon">
            <p>
              <span v-if="scope.row.shebaojc_img && [3,4,5].includes(scope.row.shebaojc_status)">
                <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-if="scope.row.shebaojc_status == 4" @click="open(scope.row, scope.$index,'shebaojc_img')"></i>
                <i class="iconfont icon-picture" style="color:#67c23a;font-size:16px;" v-if="scope.row.shebaojc_status == 3" @click="open(scope.row, scope.$index,'shebaojc_img')"></i>
                <i class="iconfont icon-picture" style="color:#e6a23c;font-size:16px;" v-if="scope.row.shebaojc_status == 5" @click="open(scope.row, scope.$index),'shebaojc_img'"></i>
              </span>
              <i v-else :class="$filterAutoStatusIcon(scope.row.shebaojc_status)"></i>
              <el-tooltip effect="dark" :disabled="!scope.row.shebaojc_err" placement="top-start">
                <template #content>
                  <div v-html="scope.row.shebaojc_err"></div>
                </template>
                <div style="display: inline-block;">
                  <span :class="$filterAutoStatusWord(scope.row.shebaojc_status)">{{$filterAutoStatusText(scope.row.shebaojc_status,'jc')}}</span>
                  <i class="iconfont icon-wenhao" v-if="scope.row.shebaojc_err" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                </div>
              </el-tooltip>
            </p>
            <div>
              <el-tooltip effect="dark" placement="top-start" content="继续">
                <el-button link size="small" :disabled="scope.row.btnStatus || !$buttonStatus('zdjz_jx')" v-if="scope.row.shebaojc_status==4" @click="continueTask(scope.row)" style="margin-left: 0px"><i class="iconfont icon-jiantou-you"></i></el-button>
              </el-tooltip>
              <!-- <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'shebaojc','检查')" ></i> -->
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="会计" align="center" width="60">
        <template #default="scope">
          <DynamicPopover>
            <template #reference>
              <div @click="poper(scope.row)" style="cursor: pointer">
                <i class="iconfont icon-huiji"></i>
              </div>
            </template>
            <KjTable :list="gridData" />
          </DynamicPopover>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="120">
        <template #default="scope">
          <el-button
            @click="companyfp(scope.row)"
            size="small"
            type="primary"
            icon="User"
            plain
            :disabled="!$buttonStatus('jzkh_fp')"
            >分配</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="float:left;margin-top:16px">
      <el-button size="small" type="primary" plain  @click="companyfpAll" :disabled="!$buttonStatus('jzkh_fp')" icon="User">
        批量分配
      </el-button>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="autoHomeIndex"/>
    </div>
  </el-tabs>

  </div>
  <autoSetting ref="autoSetting" @success="getList"/>
  <!-- 启动 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogTableVisible" title="启动方式" width="400px">
    <el-form>
      <el-form-item label="启动方式：">
        <el-select v-model="startType" placeholder="请选择启动方式" size="small" style="width:140px;">
          <el-option label="从头启动" :value="0"></el-option>
          <el-option label="从失败处启动" :value="1"></el-option>
          <!-- <el-option label="只记账" :value="2"></el-option> -->
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button size="small" @click="dialogTableVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="startSure()">确定</el-button>
      </div>
    </template>
  </el-dialog>
  <autoListCheck ref="autoListCheck"  @success="getNewList"/>
  <collectInvoiceDetail ref="collectInvoiceDetail"/>
  <sbImage ref="sbImage"/>
  <bankDeatils ref="bankDeatils" />
  <abnormalCom ref="abnormalCom" />
  <!-- 统计 -->
  <aiStatistics ref="aiStatistics"></aiStatistics>
  <companyFp ref="companyFp" code="bookkeeping" @success="getList"></companyFp>
  <autoShebaoDetail ref="autoShebaoDetail" />
</template>

<script>
import { autoSocialList,startUpAutomaticShebao,testAuto ,automaticSocialStat,jumpAutomaticShebao,confirmAutomaticShebao} from "@/api/automatic";
import { currentAccountPeriod } from '@/utils'
import { aiExportAssignCustomers } from '@/api/export'
import { companyUserList } from '@/api/company'
import selecttaxtype from "@/components/Screening/selecttaxtype";
import selectcity from "@/components/Screening/selectcity";
import selectAccreditStatus from "@/components/Screening/selectAccreditStatus";
import collectInvoiceDetail from "../../batch/components/collectInvoiceDetail.vue";
import autoSetting from "./autoSetting.vue";
import autoListCheck from "./autoListCheck.vue";
import TagNameCopy from '@/components/table-cell-render/company-name/TagNameCopy'
import sbImage from '@/components/sbImage'
import bankDeatils from "./bankDeatils.vue";
import abnormalCom from './abnormalCom.vue'
import aiStatistics from "../../layout/components/aiStatistics.vue";
import DynamicPopover from "@/components/DynamicPopover/DynamicPopover";
import KjTable from "@/components/table-cell-render/KjTable";
import companyFp from "../../company/components/companyFp.vue";
import autoShebaoDetail from "./autoShebaoDetail.vue";
import autoHomeSelect from "./autoHomeSelect.vue";
export default {
  name: "auto-billing",
  components: {
    selecttaxtype,
    selectcity,
    selectAccreditStatus,
    autoSetting,
    autoListCheck,
    collectInvoiceDetail,
    TagNameCopy,
    sbImage,
    bankDeatils,
    abnormalCom,
    aiStatistics,
    DynamicPopover,
    KjTable,
    companyFp,
    autoShebaoDetail,
    autoHomeSelect
  },
  props: {},
  data() {
    return {
      autoButtonStatus: this.$store.getters["user/user"].autoButtonStatus,
      listQuery: {
        page: 1,
        limit: 20,
        name:"",
        period:currentAccountPeriod(),
        accountingType: 2,
        statusTotal:null,
        ywlx:6
      },
      contentStyleObj: {},
      list: [],
      total: 0,
      loading:false,
      sels:[],
      startType:0,
      dialogTableVisible:false,
      activeName:'税款申报',
      daochuLoading:false,
      pageStatus: true,
      havingTask:false,
      info:{
        countTotal:0,
        countEmpower:0,
        countOk:0,
        countDqr:0,
        countErr:0,
        countJxz:0,
        countWks:0,
        countYc:0,
        countLsb:0,
        countSkSb:0,
        countWwc:0
      },
      gridData:[],
    };
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  beforeRouteLeave (to, from, next) {
    this.pageStatus = false
    next()
    // ...
  },
  created() {
    this.listQuery.limit = localStorage.getItem("autoHomeIndex")
      ? localStorage.getItem("autoHomeIndex") * 1
      : 20;
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(325);
    this.getList();
    this.initBus();
    this.getInfo()
  },
  methods: {
     // 统计
     goStatistics() {
      this.$refs.aiStatistics.init()
    },
    getInfo(){
      automaticSocialStat(this.listQuery).then(res=>{
        if(res.data.msg == 'success'){
          this.info = res.data.data
        }
      })
    },
    getNewList(){
      this.getList()
      this.getInfo()
    },
    getNewListTotal(e){
      this.listQuery.statusTotal = e ? e : null
      this.getNewList()
    },
    initBus(){
      this.$bus.off("refreshTask")
      this.$bus.on("refreshTask", (val) => {
        if(!this.pageStatus){
          return
        }
        if(this.sels.length != 0){
          return
        }
        if(this.list.length == 0){
          return
        }
        autoSocialList(this.listQuery).then(res=>{
          this.list = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
        })
      });
    },
    getList() {
      this.loading = true
      autoSocialList(this.listQuery).then((res) => {
        this.loading = false
        if (res.data.msg == "success") {
          this.list = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
        }
      });
    },
    setting(){
      if(this.sels.length == 0){
        this.$qzfMessage('请选择公司',1)
        return
      }
      let ids = []
      this.sels.map(v=>{
        ids.push(v.comId)
      })
      this.$refs.autoSetting.init(ids)
    },
    //单独设置
    settingOne(row){
      this.$refs.autoSetting.init([row.comId])
    },
    handleSelectionChange(e){
      this.sels = e
    },
    //启动
    autoStart(){
      if(this.sels.length == 0){
        this.$qzfMessage('请选择公司',1)
        return
      }
      this.dialogTableVisible = true;
      this.startType = 0
    },
    startSure(){
      let ids = []
      this.sels.map(v=>{
        ids.push(v.comId)
      })
      let param = {
        comIds:ids,
        period:this.listQuery.period,
        type:this.startType
      }
      this.startTask(param)
    },
    //继续--失败处启动
    continueTask(row){
      this.$confirm('确定继续吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          comIds:[row.comId],
          period:this.listQuery.period,
          type:1
        }
        this.startTask(param,row)
      });
    },
    //检查失败可以重启
    checkFailStart(row){
      this.$confirm('确定重启吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          comIds:[row.comId],
          period:this.listQuery.period,
          type:0
        }
        this.startTask(param,row)
      });
    },
    //重启
    reStart(){
      if(this.sels.length == 0){
        this.$qzfMessage('请选择公司',1)
        return
      }
      let ids = []
      this.sels.map(v=>{
        ids.push(v.comId)
      })
      let param = {
        comIds:ids,
        period:this.listQuery.period,
        type:0
      }
      this.startTask(param)
    },
    startTask(param,row){
      startUpAutomaticShebao(param).then(res=>{
        if(res.data.msg == 'success'){
          if(row){
            row.btnStatus = true
            setTimeout(()=>{
              row.btnStatus = false
            },10*1000)
          }
          this.havingTask = true
          setTimeout(()=>{
            this.havingTask = false
          },10*1000)
          this.$qzfMessage('任务发起成功')
          this.dialogTableVisible = false
          this.getList()
          setTimeout(()=>{
            autoSocialList(this.listQuery).then((res) => {
              if (res.data.msg == "success") {
                this.list = res.data.data.list ? res.data.data.list : [];
                this.total = res.data.data.total;
              }
            });
            this.getInfo()
          },5000)
        }
      })
    },
    checkList(){
      this.$refs.autoListCheck.init()
    },
    //账套迁出
    moveOut(){
      if(this.sels.length == 0){
        this.$qzfMessage('请选择公司',1)
        return
      }
      this.$confirm('账套迁出后，将取消本企业自动化记账报税，本账期已扣费企业，扣费不再返还并且会清除所有任务记录，确认迁出？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ids = []
        this.sels.map(v=>{
          ids.push(v.comId)
        })
        let param = {
          comIds:ids,
          type:'迁出'
        }
        testAuto(param).then(res=>{
          if(res.data.msg == 'success'){
            this.$qzfMessage("迁出成功") 
            this.getNewList()
          }
        })
      });
    },
    handleClick(tab) {
      if(tab.props.name == "税款申报") {
        this.listQuery.accountingType = 2
        this.getList()
      }if(tab.props.name == "零申报") {
        this.listQuery.accountingType = 1
        this.getList()
      }
    },
    cancel(){
      let originLimit = this.listQuery.limit
      let originAccountingType = this.listQuery.accountingType
      this.listQuery= {
        page: 1,
        limit: originLimit,
        name:"",
        period:currentAccountPeriod(),
        accountingType: originAccountingType,
        ywlx:6
      }
      this.getList()
    },
    open(row, index,flag) {
      let imageAll = []
      this.list.map(res=>{
        let arr = []
        if(res[flag].match(',')){
          let imgUrls = res[flag].split(',')
          imgUrls.map(item=>{
            if(item){
              arr.push({
                img:'https://file.listensoft.net' + item + "?" + Math.random(10),
                tableName:""
              })
            }
          })
        }else{
          if(res[flag]){
            arr.push({
              img:'https://file.listensoft.net' + res[flag] + "?" + Math.random(10),
              tableName:""
            })
          }
        }
        let url = {
          name:res.name,
          url:arr,
        }
        imageAll.push(url)
      })
      this.$refs.sbImage.dialog(imageAll, index,0)
    },
    getWjz(){
      this.$refs.abnormalCom.init(this.listQuery.period)
    },
    daochu(){
      this.daochuLoading = true
      let param = {
        query:this.listQuery,
        ids: this.sels.map(v=>{
          return v.comId
        })
      }
      aiExportAssignCustomers(param).then(res => {
        this.daochuLoading = false
        if (res.data.msg == "success") {
          window.open(res.data.data.url); 
        }
      })
    },
    // 列表点击
    poper(row) {
      companyUserList({ comId: row.comId }).then((res) => {
        this.gridData = res.data.data.list;
      });
    },
    //分配
    companyfp(row){
      let info = Object.assign({}, row);
      info.id = row.comId;
      let ids = [row.comId]
      this.$refs.companyFp.init(ids,info)
    },
    //批量分配
    companyfpAll(){
      if(this.sels.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let ids = []
      this.sels.map(v=>{
        ids.push(v.comId)
      })
      this.$refs.companyFp.init(ids)
    },
    //跳过步骤
    skipOption(row,type,text){
      this.$confirm('确定要跳过'+text+'吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        jumpAutomaticShebao({
          comId:row.comId,
          period:this.listQuery.period,
          type:type
        }).then(res=>{
          if(res.data.msg == 'success'){
            this.$qzfMessage('成功跳过');
            this.getList()
          }
        })
      });
    },
    //社保查看
    checkShebao(row){
      let param = {
        comId:row.comId,
        period:this.listQuery.period,
        tableName:'tax-cj-shebao',
        comName:row.name,
      }
      this.$refs.autoShebaoDetail.openDialog(param)
    },
     //确认
     confirmAuto(row,type,text){
      this.$confirm('确定要'+text+'吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        confirmAutomaticShebao([{
          comId:row.comId,
          period:this.listQuery.period,
          type:type
        }]).then(res=>{
          if(res.data.msg == 'success'){
            this.$qzfMessage('操作成功');
            this.getList()
          }
        })
      });
    },
    confirmAll(){
      if(this.sels.length == 0){
        this.$qzfMessage('请选择公司',1);
        return
      }
      let str = ""
      this.sels.map(v=>{
        if(v.shebaoqr_status != 2){
          str = v.name + '不是待确认状态不可确认，请重新选择！'
          return
        }
      })
      if(str){
        this.$qzfMessage(str,1);
        return
      }
      this.$confirm('确定要批量确认社保吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ids = this.sels.map((v) => ({
          comId: v.comId,
          period:  this.listQuery.period,
          type: "shebaoqr",
        }));
        confirmAutomaticShebao(ids).then(res=>{
          if(res.data.msg == 'success'){
            this.$qzfMessage('操作成功');
            this.getList()
          }
        })
      });
    },
  },
};
</script>

<style scoped lang="scss">
.top_select{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.item_icon{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    margin-right: 2px;
  }
  p{
    display: inline-block;
    font-size: 13px;
    color: #333;
  }
}

.text_width{
  width: 52px;
  display: inline-block
}
.icon-tiaoguo1{
  color: #17a2b8;
}
.style_form .el-form-item{
  margin-bottom:10px;
}
.icon-a-xinjianzidingyichicun21x1{
  font-size: 15px;
  margin-right: 2px;
}
.icon-jiantou-you{
  font-weight: 600;
}
.div_p {
  line-height: 30px;
  border-bottom: 1px solid #efefef;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child{
    border-bottom: none;
  }
}
.auto_content_size{
  width: 91%;
  margin-left: 10px;
}
</style>

