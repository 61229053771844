<template>
  <div
    id="incomeSum"
    style="width:100%;height:245px;display:inline-block"
  ></div>
</template>

<script type="text/javascript">
import * as echarts from "echarts";

let resizeFunc

export default {
  name: "incomeSum",
  props: {},
  data() {
    return {
    };
  },
  mounted() {
  },
  beforeUnmount() {
    window.removeEventListener('resize', resizeFunc)
  },
  methods: {
    init(list) {
      this.$nextTick(() => {
        this.initChart(list);
      });
    },
    getResizeFunc(chartInstance){ 
      resizeFunc = () => {
        chartInstance.resize()
      }
    },
    initChart(list) {
      let seriesData = list.map((v) => {
        return {
          value: v.count,
          name: v.type,
          amount: this.$comdify(v.amount),
        };
      });
      var myChart = echarts.init(document.getElementById("incomeSum"));
      var color = ["#62dfff", "#14a2b8", "#ffc73a"];
      myChart.setOption({
        tooltip: {
          trigger: "item",
          formatter: function(params) {
            return `类型：${params.name}<br>张数：${params.value}<br>合计：${params.data.amount}`;
          },
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "60%"],
            avoidLabelOverlap: false,
            color: color,
            label: {
              // show: false,
              // // position: "center",
              formatter: "{b}:{c}" + "\n\r" + "({d}%)" + "",
              // normal: {},
              // position: "outer",  
              // alignTo: "edge",  
              // margin: 2
              // label: {
              //   position: "outer",  
                alignTo: "edge",  
              //   margin: 2
              // },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "15",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
            },
            data: seriesData,
          },
        ],
      });
      this.getResizeFunc(myChart)
      window.addEventListener('resize', resizeFunc)
    },
  },
};
</script>
